<template>
  <div class="corporateInformation-view">
    <el-tabs v-model="activeName">
      <el-tab-pane label="打款信息" name="first">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" style="margin-top: 20px">
          <el-form-item prop="enterprise" label="企业名称"  style="width: 100%">
            <el-col :xl="10">
              <el-select clearable disabled v-model="ruleForm.enterprise" placeholder="请选择企业" style="width: 100%">
                <template v-for="(item,index) in wayList">
                  <el-option
                      :label="item.name"
                      :value="item.index"
                      :key="'info'+ index"
                  />
                </template>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item prop="settleOrder" label="结算订单" style="width: 100%">
            <el-col :xl="10">
              <el-button
                  style="background: #FFFFFF;border: 1px solid #999;color: #666666"
                  class="save"
                  type="info"
                  @click="toOrder()"
              >点击查看结算订单
              </el-button
              >
            </el-col>
          </el-form-item>
          <el-form-item prop="way" label="打款方式" style="width: 100%">
            <el-col :xl="10">
              <el-select clearable v-model="ruleForm.way" disabled placeholder="请选择打款方式" style="width: 100%">
                <template v-for="(item,index) in wayList">
                  <el-option
                      :label="item.name"
                      :value="item.index"
                      :key="'info'+ index"
                  />
                </template>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="收款人" prop="name">
            <el-col :xl="10">
              <el-input
                  disabled
                  placeholder="请输入收款人"
                  v-model="ruleForm.name"
                  clearable
                  maxlength="50"
                  show-word-limit
              />
            </el-col>
          </el-form-item>
          <el-form-item label="应付金额" prop="amountPayable">
            <el-col :xl="10">
              <el-input
                  placeholder="请输入应付金额"
                  v-model="ruleForm.amountPayable"
                  clearable
                  maxlength="20"
                  show-word-limit
                  disabled
              />
            </el-col>
          </el-form-item>
          <el-form-item label="打款金额" prop="amountMoney">
            <el-col :xl="10">
              <el-input
                  placeholder="请输入打款金额"
                  v-model="ruleForm.amountMoney"
                  clearable
                  show-word-limit
                  disabled
              />
            </el-col>
          </el-form-item>
          <el-form-item label="剩余款项" prop="remaining">
            <el-col :xl="10" style="display: flex">
<!--              <el-radio v-model="ruleForm.remaining" disabled label="0" style="align-self: center">抹零</el-radio>-->
              <div>(抹零  {{ ruleForm.amountPayable-ruleForm.amountMoney }})</div>
            </el-col>
          </el-form-item>
          <el-form-item label="打款时间" prop="dkDate">
            <el-col :xl="10">
              <el-date-picker
                  disabled
                  style="width: 100%"
                  v-model="ruleForm.dkDate"
                  type="datetime"
                  placeholder="选择日期时间">
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item prop="coverImg" label="打款凭证">
            <img :src="ruleForm.coverImg" alt="" style="width: 150px;height: 150px">
          </el-form-item>
          <el-form-item prop="desc" label="备注">
            <el-col :xl="10">
              <el-input
                  disabled
                  placeholder="请输入备注"
                  v-model="ruleForm.desc"
                  type="textarea"
              />
            </el-col>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
        title="结算订单"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="80%"
        center
    >
      <div style="display: flex">
        <div style="display: flex">
          <div style="align-self: center;margin-right: 10px">订单编号</div>
          <div>
            <el-input
                placeholder="请输入订单编号"
                v-model="order.count"
                clearable
            />
          </div>
        </div>
        <div style="margin-left: 10px">
          <el-button
              class="save"
              type="primary"
              @click="search()"
          >搜索
          </el-button
          >
        </div>
      </div>
      <commonTable
          :tableData="orderList"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :currentPage="currentPage"
          :loading="loading"
          :total="total"
      >
        <template v-slot:table>
          <el-table-column type="selection"/>
          <el-table-column prop="ordersNo" align="center" label="订单编号" />
          <el-table-column prop="orderPrice" align="center" label="订单金额" />
          <el-table-column prop="createTime" align="center" label="创建时间" />
        </template>
      </commonTable>
      <div style="display: flex;padding-bottom: 20px">
        <div style="margin-right: 20px">
          选中订单总额：￥{{moneyD}}
        </div>
        <div>
          选中订单总数：{{orderList.length}}单
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {queryPaymentVoucherByPaymentNo,selectOrdersPageList} from "@/api/settlement";

export default {
  name:'credentials',
  components: {
    commonTable
  },
  data() {
    return {
      orderList:[],
      total: 0, //总条数
      currentPage:1,
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      centerDialogVisible:false,
      activeName:'first',
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      fileList:[],
      wayList: [{index: 0, name: '微信'}, {index: 1, name: '支付宝'}],
      order:{
        count:'',
        date:'',
      },
      ruleForm: {
        enterprise:this.$route.query.companyName,
        companyId:this.$route.query.companyId,
        settleOrder:"",
        way: "",
        name: "",
        amountPayable: "",
        amountMoney: "",
        remaining: "",
        desc: "",
      },
      rules: {},
      paymentNo:''
    };
  },
  computed: {
    moneyD: function () {
      let DDmoney = 0;
      for (let i in this.orderList) {
        DDmoney += Number(this.orderList[i].orderPrice)
      }
      return DDmoney.toFixed(2)
    },
  },
  mounted() {
    let paymentNo = this.$route.query.paymentNo
    this.paymentNo = paymentNo
    this.getDetails(paymentNo)
  },
  methods: {
    async getDetails(paymentNo){
      let ajax = {
        paymentNo:paymentNo,
      }
      try {
        const result = await queryPaymentVoucherByPaymentNo(ajax);
        this.ruleForm.way = result.data.paymentType
        this.ruleForm.name = result.data.payee
        this.ruleForm.amountPayable = Number(result.data.payAmount/100).toFixed(2)
        this.ruleForm.amountMoney = Number(result.data.paymentAmount/100).toFixed(2)
        this.ruleForm.remaining = result.data.remainingAmount
        this.ruleForm.dkDate = result.data.paymentTime
        this.ruleForm.coverImg = result.data.paymentVoucher
        this.ruleForm.desc = result.data.remark
      } catch (error) {

      }
    },
    async toOrder(){
      let data = {
        associationId: localStorage.getItem("associationId"),
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        companyId: this.ruleForm.companyId,
        paymentNo:this.paymentNo,
        ifSettlement:0,
      };
      console.log(data)
      try {
        this.centerDialogVisible = true
        this.loading = true;
        const result = await selectOrdersPageList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        for (let i in list) {
          if (list[i].createTime) {
            list[i].createTime = this.renderTime(list[i].createTime)
          }
          list[i].orderPrice = (list[i].orderPrice/100).toFixed(2)
        }
        this.orderList = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.orderList = [];
      }

    },
    search(){

    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.corporateInformation-view {
  padding: 16px 24px;
}

/deep/ .el-tabs__content {
  background: #ffffff;
}

.ruleForm {
  margin-top: 24px;
  margin-left: 50px;
}

.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}

.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}

</style>


